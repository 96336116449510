import React from "react";
import "./Contact.css";

export const Contact = () => {
  return (
    <article id="contactWrapper">
      <section id="insetContact">
        <h1 style={{ color: "black" }}>Reviews</h1>
        <article id="contactCards">
          <section>
            <p>
              "From nervous beginner to confident driver, they guided me every
              step of the way."
            </p>
          </section>
          <section>
            <p>"Aman is an amazing, hardworking and sincere tutor."</p>
          </section>
          <section>
            <p>
              "The best instructor in the town. She will teach you each and
              every little detail."
            </p>
          </section>
          <section>
            <p>
              "The instructor was very knowledgeable and patient. Also the
              flexibility is a big plus"
            </p>
          </section>
        </article>
        <article id="contactInfoContent">
          <p>Satveer Sandhu</p>
          <p className="smallmargin">
            <i className="fa fa-phone inline-icon" aria-hidden="true" />
            778-710-7700
          </p>
          <p className="smallmargin">
            <i className="fa fa-envelope inline-icon" aria-hidden="true" />
            sandhu_satveer@yahoo.com
          </p>
          <p>
            <i className="fas fa-map-marker-alt inline-icon" />
            BC Lower Mainland
          </p>
        </article>
      </section>
    </article>
  );
};
