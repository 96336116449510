import React from "react";
import ScrollIntoView from "react-scroll-into-view";
import Branding from "../../resources/logo.svg";
import "./Navigation.css";

type Props = {};

type State = {
  viewNavMenu: boolean;
};

class Navigation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      viewNavMenu: false,
    };
  }

  toggleNavMenu = () => {
    this.setState({ viewNavMenu: this.state.viewNavMenu ? false : true });
  };

  disableNavMenu = () => {
    this.setState({ viewNavMenu: false });
  };

  brandingTag = (
    <img id="branding" src={Branding} alt="Welcome Driving School" />
  );

  navList = (
    <ul>
      <li>
        <ScrollIntoView selector="#servicesWrapper" smooth={true}>
          <p onClick={this.disableNavMenu}>Services</p>
        </ScrollIntoView>
      </li>
      <li>
        <ScrollIntoView selector="#contactCards">
          <p onClick={this.disableNavMenu}>Reviews</p>
        </ScrollIntoView>
      </li>
      <li>
        <ScrollIntoView selector="#contactWrapper">
          <p onClick={this.disableNavMenu}>Contact</p>
        </ScrollIntoView>
      </li>
    </ul>
  );

  contactInfo = (
    <React.Fragment>
      <article id="contactInfo">
        <p style={{ marginBottom: "3px" }}>Satveer Sandhu</p>
        <p style={{ marginBottom: "5px" }}>
          <i className="fa fa-phone inline-icon" aria-hidden="true" />
          778-710-7700
        </p>
        <p style={{ marginBottom: "5px" }}>
          <i className="fa fa-envelope inline-icon" aria-hidden="true" />
          sandhu_satveer@yahoo.com
        </p>
        <p>
          <i className="fas fa-map-marker-alt inline-icon" />
          BC Lower Mainland
        </p>
      </article>
    </React.Fragment>
  );

  content = (<React.Fragment>{this.contactInfo}</React.Fragment>);

  render() {
    const navMenu = (
      <section className={this.state.viewNavMenu ? "" : "dn"} id="navMenu">
        {this.navList}
        <hr />
        {this.content}
      </section>
    );
    return (
      <React.Fragment>
        <nav id="horizontalNav">
          <i
            id="navBars"
            className="fa fa-bars"
            onClick={this.toggleNavMenu}
          ></i>
          {this.brandingTag}
        </nav>
        {navMenu}
        <nav id="verticalNav">
          {this.brandingTag}
          {this.navList}
          <hr />
          {this.content}
        </nav>
      </React.Fragment>
    );
  }
}

export default Navigation;
