import React from "react";
import "./Services.css";
import Service from "./subcomponents/service/Service";
import "./subcomponents/service/Service.css";
import { ServiceList, ServiceType } from "./ServicesList";

export const Services = () => {
  return (
    <section id="servicesWrapper">
      <h2 id="servicesLabel">Services</h2>
      <section id="services">
        <section className="servicesContainer">
          <article className="service pointer grow">
            <h3>Cars</h3>
            <ul>
              <li>Teens, adults, refreshers; everyone is welcome!</li>
              <li>
                We provide training and knowledge tests for class 4, 5, 7 in
                both
              </li>
              <li>
                English and Punjabi We provide practical in-car training for
                class
              </li>
              <li>4, 5, 7 We provide road-test car rental</li>
            </ul>
          </article>
        </section>
        <section className="servicesContainer">
          <article className="service pointer grow">
            <h3>Trucks</h3>
            <ul>
              <li>We provide knowledge tests for class 1 and air brake</li>
              <li>We provide lessons for the MELT program</li>
              <li>
                We provide in-truck lessons for standard and automatic trucks
              </li>
            </ul>
          </article>
        </section>
      </section>
    </section>
  );
};
